<template>
  <div class="RealplayerFeatures">
    <div class="t-c text2">RealPlayer 功能</div>
    <download-btn from="realplayer_features" style="margin:50px 0 79px; 0"></download-btn>
    <!-- 内容介绍部分 -->
    <div class="content">
      <abort-real :show-list="list"></abort-real>
      <div class="t-r text3">*部分功能需订阅尊享套餐。</div>
      <download-btn from="realplayer_features"></download-btn>
    </div>

    <!-- 底部mobile模块 -->
    <div class="mobile-show">
      <div class="t-c mobile-show-title">使用 RealPlayer 移动版完善您的体验</div>
      <!-- 文字模块 -->
      <div class="mobile-show-content">
        <!-- 左侧模块 -->
        <div class="msc-l">
          <!-- 右侧图片 -->
          <div class="msc-r mobile-type">
            <img src="@/img/mobile-phone.png" alt="">
          </div>
          <div class="c-b text5">新版本！</div>
          <div class="text6">RealPlayer 移动版</div>
          <div class="p_ text7" @click="openLink('/realplayer-mobile',true)">了解更多 ›</div>
          <div class="text8">RealPlayer 的必备伴侣。随时随地访问您的 RealPlayer 媒体库、同步和离线观看视频、投射到大屏幕、将您的视频备份到 RealPlayer Cloud 等等！</div>
          <div class="scancode pc-type">
            <div class="scancode-google" @click="openLink(toGoogleLink)">
                <img src="@/img/googleplay.svg" alt="">
                <img src="@/img/googleplay_QR.png" alt="">
            </div>
            <div class="scancode-appstore"  @click="openLink(toAppStoreLink)">
                <img src="@/img/appstore.svg" alt="">
                <img src="@/img/appstore_QR.svg" alt="">
            </div>
          </div>
          <div class="scancode mobile-type">
            <div class="scancode-google" @click="openLink(toGoogleLink)" v-if="type == 'andriod'">
                <img src="@/img/googleplay.svg" alt="">
                <img src="@/img/googleplay_QR.png" alt="">
            </div>
            <div class="scancode-appstore"  @click="openLink(toAppStoreLink)" v-if="type == 'ios'">
                <img src="@/img/appstore.svg" alt="">
                <img src="@/img/appstore_QR.svg" alt="">
            </div>
          </div>
          <div class="t-c text9">使用照相手机扫描二维码，安装应用程序</div>
        </div>
        <!-- 右侧图片 -->
        <div class="msc-r pc-type">
          <img src="@/img/mobile-phone.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AbortReal from "@/components/AbortReal.vue"
  import DownloadBtn from "@/components/DownloadBtn.vue"
  import settingLink from '@/js/global.js'
  export default {
   name: 'RealplayerFeatures',
    data(){
      return{
        toGoogleLink:settingLink.googleLink,
        toAppStoreLink:settingLink.appStoreLink,
        // 模块展示内容
        list:[
          {
            img:require('@/img/realplayer_features01.svg'),
            title:'从数千个站点下载视频，一键完成！',
            content:'从数千个站点下载视频，只需一键完成！ 边看边下。畅享不限速，画质高达 4K。使用 RealPlayer 移动版标记手机上的视频，它们将自动添加到您的电脑媒体库中。'
          },{
            img:require('@/img/realplayer_features02.svg'),
            title:'在大屏幕上欣赏您的视频！',
            content:'播放任意视频格式。使用“投屏功能”将您的视频实况带到电视上。直接从您的 RealPlayer 媒体库投射和流式传输，在沙发上欣赏巨幕影像。RealPlayer 支持 CD 和 DVD 播放和刻录。'
          },{
            img:require('@/img/realplayer_features03.svg'),
            title:'连接到 RealPlayer 移动版，随时随地访问您的内容。',
            content:'RealPlayer 电脑和移动版紧密集成，因此您可以随身携带媒体库。即使没有连接到家庭网络，也可以访问、流式传输和同步您的媒体——视频一键可及。 将您手机上拍摄的视频传输到电脑操作简单，并且您可以使用云备份确保您的内容安全且可访问。'
          },{
            img:require('@/img/realplayer_features04.svg'),
            title:'自动查找名人并按人物整理您的视频库',
            content:'即刻识别您视频中的名人并查找有趣镜头。 节省时间并根据您认识的人物自动创建文件夹和标记视频。 浏览、搜索和发现您所有视频中的人物。只需选择，随时启用该功能。********************敬请期待。'
          },{
            img:require('@/img/realplayer_features05.svg'),
            title:'播放和刻录 DVD*',
            content:'使用内置刻录软件制作专业的 CD 和 DVD。 只需点击一个按钮，即可刻录您喜爱的播放列表或视频。'
          },{
            img:require('@/img/realplayer_features06.svg'),
            title:'所有媒体归于一处，简化您的生活。',
            content:'在一个地方组织你的媒体库，这样你就可以找到你想要的，当你需要它。 访问新的“我的媒体”选项卡中的所有内容。 创建混合播放列表的视频和音乐与容易拖放。 整理和自动扫描您的库，以删除重复的视频*。 与朋友和家人分享你最喜欢的媒体。 '
          },{
            img:require('@/img/realplayer_features07.svg'),
            title:'将您的视频设为私人*',
            content:'在媒体库中选择想要保持私密的视频，以便只有您能访问。这像设置 PIN 码那么简单。'
          },{
            img:require('@/img/realplayer_features08.svg'),
            title:'转换视频',
            content:'将视频转换为最常见的视频格式，以便您以卓越的画质观看所有视频。您还可以将视频文件转换为 MP3 音频文件，以便随时随地轻松收听'
          },{
            img:require('@/img/realplayer_features09.svg'),
            title:'节省空间和移除重复项',
            content:'清理！快速自动识别您媒体库中重复的视频或音频文件。RealPlayer 推荐最佳版本，以继续使用一组智能滤镜。 更多空间有助于您的电脑运行更顺畅。'
          },{
            img:require('@/img/realplayer_features10.svg'),
            title:'享受更多功能以增强您的体验',
            content:'轻松修剪视频，使其更易于分享。 为您的音乐收藏设置华丽的专辑图案。 自动识别和标记曲目以进行 CD 刻录。使用高级均衡器获得您想要播放或 CD 刻录的声音······'
          },
        ],
        // 当前设备类型
        type:'', // pc/andriod/ios
      }
    },
    components: {
      AbortReal,DownloadBtn
    },
    created:function(){
      window.scrollTo(0,0);
      var type = window.localStorage.getItem('type'); // 获取当前设备类型
      this.type = type;
    },
    methods:{
      openLink:function(url,type=false){
        if(type){
          this.$router.push(url);
        }else{
          window.open(url,'_blank');
        }
      }
    }
}
</script>
<style scoped>
@media screen and (min-width: 480px) {
  .mobile-type{display: none !important;}
    .text1{font: bold 22px Red Hat Display!important;}
  .text2{font: bold 42px Red Hat Display!important;}
  .text3{font: normal 14px Red Hat Display!important;color: #525252;}
  .text5{font: bold 16px Red Hat Display!important;padding-top: 18px;}
  .text6{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;}
  .text7{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px;color: #00a7ff!important;}
  .text8{font: normal 15px Red Hat Display!important;color: #525252;}
  .text9{font: normal 15px Red Hat Display!important;color: #525252;padding-top: 26px;}

  .content{
    padding: 50px 16%;
    background: transparent linear-gradient(180deg,#f8f8f8 0,#eaeaea 100%) 0 0 no-repeat padding-box;
  }

  .mobile-show{max-width:730px;margin:0 auto;padding-top:25px;padding: 25px 16%;}
  .mobile-show-title{
    font: bold 40px Red Hat Display!important;
    padding-bottom: 20px;
  }
  .mobile-show-content{
    display: flex;
    justify-content: space-between;
  }
  .msc-l{
    flex:1;
  }
  .msc-r img{max-height: 433px!important;}

  /* 二维码 */
  .scancode{display: flex;justify-content: space-evenly;}
  .scancode-google{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-google img:nth-child(2){width: 125px;height: 125px;}
  .scancode-appstore{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-appstore img:nth-child(2){width: 125px;height: 125px;}
}
@media screen and (min-width: 320px) and (max-width:480px){
  .pc-type{display: none !important;}
  .text1{font: bold 12px Red Hat Display!important;}
  .text2{font: bold 22px Red Hat Display!important;}
  .text3{font: normal 14px Red Hat Display!important;color: #525252;}
  .text5{font: bold 16px Red Hat Display!important;padding-top: 18px;}
  .text6{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;}
  .text7{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px;color: #00a7ff!important;}
  .text8{font: normal 15px Red Hat Display!important;color: #525252;}
  .text9{font: normal 15px Red Hat Display!important;color: #525252;padding-top: 26px;}

  .content{
    padding: 50px 16%;
    background: transparent linear-gradient(180deg,#f8f8f8 0,#eaeaea 100%) 0 0 no-repeat padding-box;
  }

  .mobile-show{max-width:730px;margin:0 auto;padding-top:25px;padding: 25px 16%;}
  .mobile-show-title{
    font: bold 20px Red Hat Display!important;
    padding-bottom: 20px;
  }
  .mobile-show-content{
    display: flex;
    justify-content: space-between;
  }
  .msc-l{
    flex:1;
  }
  .msc-r img{max-height: 433px!important;}

  /* 二维码 */
  .scancode{display: flex;justify-content: space-evenly;}
  .scancode-google{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-google img:nth-child(2){width: 125px;height: 125px;}
  .scancode-appstore{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px;}
  .scancode-appstore img:nth-child(2){width: 125px;height: 125px;}
}



</style>